import axios from 'axios'
import Cookies from 'js-cookie'
import * as types from '../mutation-types'
// import * as roles from '../../middleware/roles'
import * as system_user from '../../constants/system_user'
import {TransactionType} from "../../constants/transaction";

// state
export const state = {
  user: null,
  safetyDialog: false,
  token: Cookies.get('session-id'),
  autoAssignMode: true,
  workingMode: true,
  topUpAssignment: [],
  withdrawalAssignment: [],
  unassignedTopUpTasks: [],
  unassignedWithdrawalTasks: [],
  itemsPerPage: 50,
  editingTask: null,
  isSysErrLogMute: false,
  hasNewSysErrLog: false,
};

// getters
export const getters = {
  user: state => state.user,
  safetyDialog: state => state.safetyDialog,
  autoAssignMode: state => state.autoAssignMode,
  workingMode: state => state.workingMode,
  topUpAssignment: state => state.topUpAssignment,
  withdrawalAssignment: state => state.withdrawalAssignment,
  unassignedTopUpTasks: state => state.unassignedTopUpTasks,
  unassignedWithdrawalTasks: state => state.unassignedWithdrawalTasks,
  token: state => state.token,
  check: state => state.user !== null,
  isRoot: state => state.user !== null && state.user.user_type === system_user.UserType.ROOT,
  isManager: state => state.user !== null && state.user.user_type === system_user.UserType.MANAGER,
  isCsAgent: state => state.user !== null && state.user.user_type === system_user.UserType.CS_AGENT,
  isCsAgentBot: state => state.user !== null && state.user.user_type === system_user.UserType.CS_AGENT_BOT,
  isReporter: state => state.user !== null && state.user.user_type === system_user.UserType.REPORTER,
  isClient: state => state.user !== null && state.user.user_type === system_user.UserType.CLIENT,
  isClientCsa: state => state.user !== null && state.user.user_type === system_user.UserType.CLIENT_CSA,

  editingTask: state => state.editingTask,

  isSysErrLogMute: state => state.isSysErrLogMute,
  hasNewSysErrLog: state => state.hasNewSysErrLog,
};

// mutations
export const mutations = {
  // [types.SAVE_TOKEN] (state, { token, remember }) {
  [types.SAVE_TOKEN](state, {token}) {
    state.token = token;
    // Cookies.set('token', token, { expires: remember ? 365 : null })
  },

  [types.FETCH_USER_SUCCESS](state, {user}) {
    state.user = user;
  },

  [types.FETCH_USER_FAILURE](state) {
    state.token = null;
    Cookies.remove('session-id')
  },

  [types.LOGOUT](state) {
    state.user = null;
    state.safetyDialog = false;
    state.token = null;
    state.workingMode = null;
    state.topUpAssignment = [];
    state.withdrawalAssignment = [];
    state.unassignedTopUpTasks = [];
    state.unassignedWithdrawalTasks = [];

    Cookies.remove('session-id')
  },

  [types.UPDATE_USER](state, {user}) {
    state.user = user
  },

  [types.UPDATE_USER_API_SECRET](state, value) {
    state.user.api_secret = value
  },

  [types.UPDATE_WORKING_MODE](state, {workingMode}) {
    state.workingMode = workingMode
  },

  [types.UPDATE_ASSIGNMENT](state, {type, assignment}) {
    if (Array.isArray(assignment)) {
      if (type === TransactionType.TOP_UP || type === TransactionType.COLLECT) {
        state.topUpAssignment = assignment;
      } else if (type === TransactionType.WITHDRAW || type === TransactionType.PAY) {
        state.withdrawalAssignment =  assignment;
      }
    }
  },
  [types.ADD_ASSIGNMENT](state, assignment) {
    if (assignment.transaction_type === TransactionType.TOP_UP || assignment.transaction_type === TransactionType.COLLECT) {
      state.topUpAssignment.push(assignment)
    } else if (assignment.transaction_type === TransactionType.WITHDRAW || assignment.transaction_type === TransactionType.PAY) {
      state.withdrawalAssignment.push(assignment)
    }
  },
  [types.REMOVE_ASSIGNMENT](state, id) {
    state.topUpAssignment = state.topUpAssignment.filter((item) => item.transaction_number !== id);
    state.withdrawalAssignment = state.withdrawalAssignment.filter((item) => item.transaction_number !== id);
  },
  [types.UPDATE_UNASSIGNED_TASKS](state, {type, unassignedTasks}) {
    if (Array.isArray(unassignedTasks)) {
      if (type === TransactionType.TOP_UP || type === TransactionType.COLLECT) {
        state.unassignedTopUpTasks = unassignedTasks;
      } else if (type === TransactionType.WITHDRAW || type === TransactionType.PAY) {
        state.unassignedWithdrawalTasks = unassignedTasks;
      }
    }
  },
  [types.ADD_UNASSIGNED_TASKS](state, unassignedTasks) {
    if (unassignedTasks.transaction_type === TransactionType.TOP_UP || unassignedTasks.transaction_type === TransactionType.COLLECT) {
      state.unassignedTopUpTasks.push(unassignedTasks)
    } else if (unassignedTasks.transaction_type === TransactionType.WITHDRAW || unassignedTasks.transaction_type === TransactionType.PAY) {
      state.unassignedWithdrawalTasks.push(unassignedTasks)
    }
  },
  [types.REMOVE_UNASSIGNED_TASKS](state, id) {
    state.unassignedTopUpTasks = state.unassignedTopUpTasks.filter((item) => item.transaction_number !== id);
    state.unassignedWithdrawalTasks = state.unassignedWithdrawalTasks.filter((item) => item.transaction_number !== id);
  },

  [types.UPDATE_SAFETY_DIALOG](state, flag) {
    state.safetyDialog = flag
  },

  [types.UPDATE_EDITING_TASK](state, editingTask) {
    state.editingTask = editingTask
  },

  [types.SYS_ERR_LOG_MUTABLE](state) {
    state.isSysErrLogMute = !state.isSysErrLogMute;
  },
  [types.SYS_ERR_LOG_NEW_ITEM](state, hasNewSysErrLog) {
    state.hasNewSysErrLog = hasNewSysErrLog;
  },
};

// actions
export const actions = {
  // saveToken ({ commit, dispatch }, payload) {
  saveToken({commit}, payload) {
    commit(types.SAVE_TOKEN, payload)
  },

  async fetchUser({commit, dispatch}) {
    try {
      const {data} = await axios.get('/app/api/whoami')

      commit(types.FETCH_USER_SUCCESS, {user: data.data})

      if (data.data.user_type === system_user.UserType.CS_AGENT || data.data.user_type === system_user.UserType.CS_AGENT_BOT) {
        dispatch('updateWorkingMode', data.data.working)
        dispatch('fetchAssignment');
      } else if (data.data.user_type === system_user.UserType.ROOT || data.data.user_type === system_user.UserType.MANAGER) {
        dispatch('fetchAssignment');
      } else if (data.data.user_type === system_user.UserType.CLIENT || data.data.user_type === system_user.UserType.CLIENT_CSA) {
        dispatch('fetchAssignment');
      }
    } catch (e) {
      commit(types.FETCH_USER_FAILURE)
    }
  },

  updateUser({commit}, payload) {
    commit(types.UPDATE_USER, payload)
  },

  updateUserApiSecret({commit}, payload) {
    commit(types.UPDATE_USER_API_SECRET, payload)
  },

  async logout({commit, dispatch}) {
    try {
      await axios.post('/app/api/logout')
    } catch (e) {
      dispatch('snackbar/openSnackbar', {
        message: e,
        color: 'error',
      }, {root: true})
    }

    commit(types.LOGOUT)
  },

  async fetchOauthUrl(ctx, {provider}) {
    const {data} = await axios.post(`/api/oauth/${provider}`)
    return data.url
  },

  fetchAssignment({getters, commit, dispatch}) {
    if (getters.isRoot || getters.isManager || getters.isCsAgent || getters.isCsAgentBot) {

      // topUpAssignment
      // withdrawalAssignment
      // unassignedTopUpTasks
      // unassignedWithdrawalTasks
      axios.get('/app/api/transaction/pending', {
        params: {type: [TransactionType.TOP_UP, TransactionType.COLLECT], count: state.itemsPerPage}
      })
        .then(function (response) {
          const {data} = response.data;
          commit(types.UPDATE_ASSIGNMENT, {
            type: TransactionType.TOP_UP,
            assignment: data.data
          })
        })
        .catch(function (error) {
          dispatch('snackbar/openSnackbar', {
            message: error.response.data.message,
            color: 'error',
          }, {root: true})
        });

      axios.get('/app/api/transaction/pending', {
        params: {type: [TransactionType.WITHDRAW, TransactionType.PAY], count: state.itemsPerPage}
      })
        .then(function (response) {
          const {data} = response.data;
          commit(types.UPDATE_ASSIGNMENT, {
            type: TransactionType.WITHDRAW,
            assignment: data.data
          })
        })
        .catch(function (error) {
          dispatch('snackbar/openSnackbar', {
            message: error.response.data.message,
            color: 'error',
          }, {root: true})
        });

      axios.get('/app/api/transaction/unassigned', {
        params: {type: [TransactionType.TOP_UP, TransactionType.COLLECT], count: state.itemsPerPage}
      })
        .then(function (response) {
          const {data} = response.data;
          commit(types.UPDATE_UNASSIGNED_TASKS, {
            type: TransactionType.TOP_UP,
            unassignedTasks: data.data
          })
        })
        .catch(function (error) {
          dispatch('snackbar/openSnackbar', {
            message: error.response.data.message,
            color: 'error',
          }, {root: true})
        });

      axios.get('/app/api/transaction/unassigned', {
        params: {type: [TransactionType.WITHDRAW, TransactionType.PAY], count: state.itemsPerPage}
      })
        .then(function (response) {
          const {data} = response.data;
          commit(types.UPDATE_UNASSIGNED_TASKS, {
            type: TransactionType.WITHDRAW,
            unassignedTasks: data.data
          })
        })
        .catch(function (error) {
          dispatch('snackbar/openSnackbar', {
            message: error.response.data.message,
            color: 'error',
          }, {root: true})
        });
    } else if (getters.isClient || getters.isClientCsa) {
      axios.get('/app/api/transaction/pending', {
        params: {type: [TransactionType.TOP_UP, TransactionType.COLLECT], count: state.itemsPerPage}
      })
        .then(function (response) {
          const {data} = response.data;
          commit(types.UPDATE_ASSIGNMENT, {
            type: TransactionType.TOP_UP,
            assignment: data.data
          })
        })
        .catch(function (error) {
          dispatch('snackbar/openSnackbar', {
            message: error.response.data.message,
            color: 'error',
          }, {root: true})
        });

      axios.get('/app/api/transaction/pending', {
        params: {type: [TransactionType.WITHDRAW, TransactionType.PAY], count: state.itemsPerPage}
      })
        .then(function (response) {
          const {data} = response.data;
          commit(types.UPDATE_ASSIGNMENT, {
            type: TransactionType.WITHDRAW,
            assignment: data.data
          })
        })
        .catch(function (error) {
          dispatch('snackbar/openSnackbar', {
            message: error.response.data.message,
            color: 'error',
          }, {root: true})
        });
    }
  },

  updateAssignment({commit}, assignment) {
    commit(types.UPDATE_ASSIGNMENT, assignment)
  },

  addAssignment({commit}, assignment) {
    commit(types.ADD_ASSIGNMENT, assignment)
  },

  addUnassignedTask({commit}, assignment) {
    commit(types.ADD_UNASSIGNED_TASKS, assignment)
  },

  removeAssignment({commit}, id) {
    commit(types.REMOVE_ASSIGNMENT, id)
  },

  removeUnassignedTask({commit}, id) {
    commit(types.REMOVE_UNASSIGNED_TASKS, id)
  },

  updateWorkingMode({commit, dispatch, state}, isWorking) {
    try {
      let url = ''
      if (isWorking) {
        url = `/app/api/user/${state.user.user_id}/working/on`
      } else {
        url = `/app/api/user/${state.user.user_id}/working/off`
      }
      axios.post(url)
        .then(function () {
          commit(types.UPDATE_WORKING_MODE, {
            workingMode: isWorking,
          })
        })
        .catch(function (error) {
          commit(types.UPDATE_WORKING_MODE, {
            workingMode: false,
          })
          dispatch('snackbar/openSnackbar', {
            message: error.response.data.message,
            color: 'error',
          }, {root: true})
        })
      dispatch('fetchAssignment');
    } catch (e) {
      dispatch('snackbar/openSnackbar', {
        message: e,
        color: 'error',
      }, {root: true})
    }
  },

  updateSafetyDialog({commit}, payload) {
    commit(types.UPDATE_SAFETY_DIALOG, payload)
  },

  updateEditingTask({commit}, payload) {
    commit(types.UPDATE_EDITING_TASK, payload)
  },

  setSysErrLogMute({commit}) {
    commit(types.SYS_ERR_LOG_MUTABLE);
  },
  setSysErrLogNewItem({commit}, payload) {
    commit(types.SYS_ERR_LOG_NEW_ITEM, payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
