export const locales = {
  zh_CN: "中文",
  en: "English",
}

export const languagesArray = ['zh_CN', 'en'];

export function supportedLocalesInclude(locale) {
  return Object.keys(locales).includes(locale)
}
