import Vue from 'vue'
import VueI18n from 'vue-i18n'
import * as supportedLocales from './i18n/supported-locales'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      const vvLocale = require(/* webpackChunkName: '' */ `vee-validate/dist/locale/${locale}`)
      messages[locale] = {...locales(key), ...{
          validations: vvLocale
        }
      }
      // const asd = import(/* webpackChunkName: '' */ `vee-validate/dist/locale/${locale}`)
      // console.log(smt) //eslint-disable-line
    }
  })
  // console.log(messages) //eslint-disable-line
  return messages
}

function checkDefaultLanguage() {
  let matched = null
  let languages = supportedLocales.languagesArray;
  languages.forEach(lang => {
    if (lang === navigator.language) {
      matched = lang
    }
  })
  if (!matched) {
    languages.forEach(lang => {
      let languagePartials = navigator.language.split('-')[0]
      if (lang === languagePartials) {
        matched = lang
      }
    })
  }
  if (!matched) {
    languages.forEach(lang => {
      let languagePartials = navigator.language.split('-')[0]
      if (lang.split('-')[0] === languagePartials) {
        matched = lang
      }
    })
  }
  return matched
}

export const selectedLocale = checkDefaultLanguage() || process.env.VUE_APP_I18N_LOCALE;

export default new VueI18n({
  locale: selectedLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: loadLocaleMessages()
})
