import * as types from '../mutation-types'

// state
export const state = {
  navBar: null,
};

// getters
export const getters = {
  navBar: state => state.navBar,
};

// mutations
export const mutations = {
  [types.UPDATE_NAVBAR_LOADING](state, {navBar}) {
    state.navBar = navBar;
  },
};

// actions
export const actions = {
  update({commit}, payload) {
    commit(types.UPDATE_NAVBAR_LOADING, payload)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
