import * as types from '../mutation-types'
import i18n, { selectedLocale } from '../../plugins/i18n'
import vuetify from '../../plugins/vuetify'

// const { locale, locales } = window.config

// state
export const state = {
  locale: selectedLocale,
}

// getters
export const getters = {
  locale: state => state.locale,
  locales: state => state.locales
}

// mutations
export const mutations = {
  [types.SET_LOCALE] (state, locale) {
    state.locale = locale
  }
}

// actions
export const actions = {
  setLocale ({ commit }, locale ) {
    i18n.locale = locale; // important!
    vuetify.framework.lang.current = locale;

    commit(types.SET_LOCALE, locale)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
